.card {
  // height: 100vh;
  background-image: url("../../assets/background.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;

  .card-animation {
    animation: fadeIn 2s;
    -webkit-animation: fadeIn 2s;
    -moz-animation: fadeIn 2s;
    -o-animation: fadeIn 2s;
    -ms-animation: fadeIn 2s;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .card-container {
    padding: 20px 0px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    opacity: 1;
    transition: 0.5s;
    .card-img {
      width: 100%;
    }
  }
}
