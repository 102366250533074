.ui-button {
  display: inline-block;
  border: none;
  background-color: #168cb1;
  color: white;
  padding: 12px 26px;
  font-size: 16px;
  border-radius: 8px;
  font-weight: 800;
  cursor: pointer;
  p {
    display: inline;
    color: white;
    font-size: 16px;
  }
  .ui-button-loading {
    margin-right: 8px;
    display: inline;
  }
}

.ui-button-disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
