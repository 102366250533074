.form-card {
  background-color: white;
  padding: 14px;
  border: 10px solid white;
  box-shadow: 0 0 0 2px #f17298 inset;
  .form-card-title {
    font-size: 30px;
    text-align: center;
    color: #f17298;
  }
  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 20px;
  }
  .button-container {
    display: flex;
    justify-content: center;
  }
  .react-switch-selector-wrapper {
    height: 40px;
    border-radius: 8px;
    font-family: "Century Gothic", serif;
    font-weight: 800;
    background: #c3dee8;
  }
}
