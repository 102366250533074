.music-bar {
  display: flex;
  align-items: center;
  justify-content: center;

  .music {
    height: 40px;
  }

  svg {
    padding: 4px 4px 2px 4px;
    color: white;
    width: 20px;
  }

  .music-controls {
    width: 60px;
    text-align: center;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.75);
  }
}
