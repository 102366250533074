@import url("https://fonts.googleapis.com/css2?family=Amatic+SC&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Prata&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pinyon+Script&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Courgette&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Libre+Bodoni:ital@1&display=swap");

.App {
  text-align: center;
  font-family: "Century Gothic", cursive;
  letter-spacing: 2px;
  color: #168cb1;
}

.App-container {
  padding-top: 61.5px;
  max-width: 720px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 44px;
  @media only screen and (max-width: 760px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.container-lg {
  max-width: 768px;
  margin: 0px auto;
  @media only screen and (max-width: 768px) {
    padding: 0 20px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  margin: 0px;
}

h1 {
  font-weight: 200;
  font-size: 120px;
  text-transform: uppercase;
  letter-spacing: 3px;
}

h2 {
  font-family: "Century Gothic", serif;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 42px;
  @media only screen and (max-width: 760px) {
    font-size: 34px;
  }
}

h3 {
  font-family: "Century Gothic", serif;
  font-weight: 800;
  font-size: 16px;
  letter-spacing: 4px;
  color: #168cb1;
  @media only screen and (max-width: 760px) {
    font-size: 14px;
  }
}

p {
  font-family: "Century Gothic", serif;
  font-weight: 800;
  font-size: 16px;
  color: #168cb1;
  @media only screen and (max-width: 760px) {
    font-size: 14px;
  }
}

h4 {
  font-family: "Century Gothic", serif;
  font-weight: 800;
  font-size: 14px;
  letter-spacing: 4px;
  color: #168cb1;
  @media only screen and (max-width: 640px) {
    font-size: 12px;
  }
}

h6 {
  font-family: "Century Gothic", serif;
  font-weight: 800;
  font-size: 18px;
  color: #168cb1;
  @media only screen and (max-width: 640px) {
    font-size: 16px;
  }
}

h5 {
  font-family: "Century Gothic", serif;
  font-weight: 800;
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 6px;
  color: #168cb1;
  @media only screen and (max-width: 760px) {
    font-size: 16px;
  }
}

a {
  font-size: 20px;
}

.home-container {
  display: flex;
  flex-direction: column;
  gap: 44px;
  @media only screen and (max-width: 760px) {
    gap: 22px;
  }
}
.parents-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  span {
    color: #168cb1;
  }
  .parents-1 {
    display: flex;
    flex-direction: column;
    gap: 4px;
    text-align: left;
  }
  .parents-2 {
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-align: right;
  }
}

.brides-groom-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  img {
    padding: 0px 20px;
  }
}

.save-the-date {
  font-weight: 800;
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 4px;
  color: #168cb1 !important;
}
